import React from "react"
import Style from "./footer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithubSquare } from "@fortawesome/free-brands-svg-icons"
import { Container } from "react-bootstrap"

function Footer() {
  return (
    <footer className={Style.wrap}>
      <Container>
        <span className={Style.sns}>
          <a
            href="https://github.com/hirokishingu"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon icon={faGithubSquare} />
          </a>
        </span>
      </Container>
      <p>©HirokiShingu All Rights Reserved.</p>
    </footer>
  )
}

export default Footer
