import React from "react"
import PropTypes from "prop-types"
import Header from "../header/header"
import Footer from "../footer/footer"
import "bootstrap/dist/css/bootstrap.min.css"
import Style from "./layout.module.scss"

const Layout = ({ children }) => {
  return (
    <div className={Style.wrap}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
