import React from "react"
import Style from "./header.module.scss"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"

function Header() {
  return (
    <header className="bg-dark">
      <Navbar expand="md" bg="primary" variant="dark">
        <Navbar.Brand>
          <Link className="navbar-brand" to="/">
            Home
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav as="ul" className={Style.navItems}>
            <Nav.Item as="li">
              <Link to="/about/" className="nav-link" activeClassName="active">
                About
              </Link>
            </Nav.Item>
            <Nav.Link
              href="https://covid-dashboard-14174.web.app/"
              target="_blank"
              rel="noreferrer"
            >
              Covid19 App
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
